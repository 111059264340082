import React, {useEffect, useState} from 'react';
import './app.scss';
import {MainPage} from '../features/MainPage/MainPage';
import loadingSVG from '../assets/loading/loadingSVG.svg'

function App() {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(()=> {
        setTimeout(()=>{
            setIsLoading(false)
        }, 2000)
    }, [])

    return <div className='app'>
        {isLoading && <img src={loadingSVG} className='loading' alt="loading"/>}
        {!isLoading &&  <MainPage/>}
    </div>
}

export default App;



