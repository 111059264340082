import React from 'react';
import style from './Header.module.scss'
import {Menu} from './Menu/Menu';
import {BurgerMenu} from './BurgerMenu/BurgerMenu';


export const Header = () => {
    return <div className={style.header}>
        <div className={style.container}>
            <BurgerMenu/>
            <Menu/>

        </div>
    </div>
};
