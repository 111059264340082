import React from 'react';
import style from './Works.module.scss'
import {Projects} from './Project/Projects';
import {Title} from '../../common/components/Title/Title';
import socialNetworkImage from '../../assets/image/project/socialNetworkImage.jpg'
import todoListImage from '../../assets/image/project/todoListImage2.png'
import restaurantImage from '../../assets/image/project/restaurantImage.png'
import medecaImage from '../../assets/image/project/medecaImage.png'
import vetclinic from '../../assets/image/project/vetclinic.png'
import aristinova from '../../assets/image/project/aristinova.png'
import {Buttons} from '../../common/components/Buttons/Buttons';

export const Works = () => {
    return <div id={'works'}>
        <div className={style.projects}>
            <div className={style.container}>
                <div className={style.projects_block}>
                    <div className={style.block_title}>
                        <Title title={'Works'}/>
                    </div>
                    <div className={style.projects_elements}>
                        <div className={style.projects_elements_div1}>
                            <Projects
                                image={'https://firebasestorage.googleapis.com/v0/b/svetailo-db.appspot.com/o/portfolio%2Fimg%2Fprojects%2Farestinova-project.png?alt=media&token=e5f5951c-e287-40e6-b490-3fd17bf868d1'}
                                title={'Arestinova online store App'}
                                text={'Arestinova online store App. JS, TS, React, Redux, Redux Form, HTML, CSS, SASS, And Design were used to create.'}
                                linkCode={''}
                                linkApp={'https://arestinova.ru/'}
                            />
                        </div>
                        <div className={style.projects_elements_div2}>
                            <Projects
                                image={vetclinic}
                                title={'Vet Clinic'}
                                text={'Only JavaScript, HTML, SCSS, were used to create.'}
                                linkCode={''}
                                linkApp={'https://vitavet.pro/'}
                            />
                        </div>
                        <div className={style.projects_elements_div3}>
                            <Projects
                                image={todoListImage}
                                title={'To-do list App'}
                                text={'To-do List App. JS, TS, React, Redux, Redux-Toolkit, Formik, HTML, CSS, SASS, and Material UI, were used to create.'}
                                linkCode={'https://github.com/VictorSvetailo'}
                                linkApp={'https://github.com/VictorSvetailo'}
                            />
                        </div>
                        <div className={style.projects_elements_div4}>
                            <Projects
                                image={'https://firebasestorage.googleapis.com/v0/b/svetailo-db.appspot.com/o/portfolio%2Fimg%2Fprojects%2FrestaurantImage.png?alt=media&token=09b7c518-3f01-4e41-b3e7-b59daf719249'}
                                title={'Restaurant Gericht'}
                                text={'Website. JS, HTML, CSS, SASS, GULP, SWIPER JS were used for creation.'}
                                linkCode={'https://github.com/VictorSvetailo'}
                                linkApp={'https://github.com/VictorSvetailo'}
                            />
                        </div>
                        <div className={style.projects_elements_div5}>
                            <Projects
                                image={socialNetworkImage}
                                title={'Social Network'}
                                text={'Social Network App. JS, TS, React, Redux, Redux Form, HTML, CSS, SASS, And Design were used to create.'}
                                linkCode={'https://github.com/VictorSvetailo'}
                                linkApp={''}
                            />
                        </div>
                        <div className={style.projects_elements_div6}>
                            <Projects
                                image={medecaImage}
                                title={'Medeca'}
                                text={'Medeca App. JS, TS, React, Redux, Redux-Toolkit, Formik, HTML, CSS, SASS, and Material UI, were used to create.'}
                                linkCode={'https://github.com/VictorSvetailo'}
                                linkApp={''}
                            />
                        </div>
                    </div>
                    <Buttons link={'https://github.com/VictorSvetailo'} text={'View More'}/>
                </div>
            </div>
        </div>

    </div>

};

