import style from './Projects.module.scss';
import React from 'react';
import {Button} from 'react-scroll';
import {Buttons} from '../../../common/components/Buttons/Buttons';


type PropsType = {
    image: string
    title: string
    text: string
    linkCode?: string
    linkApp?: string
}

export const Projects: React.FC<PropsType> = ({image, title, text,linkCode, linkApp}) => {
    return <div className={style.skill_element}>
        <div className={style.skill_element_block}>
            <div className={style.skill_element_image}>
                <img src={image} alt="React"/>
            </div>
            <div className={style.skill_element_description}>
                <div className={style.description_elements}>
                    <p>{text}</p>
                    <div className={style.description_elements_btn}>
                        {linkCode && <Buttons link={linkCode} text={'View Code'}/>}
                        {linkApp && <Buttons link={linkApp} text={'View App'}/>}
                    </div>
                </div>
            </div>
        </div>
        <h2 className={style.skill_element_title}>{title}</h2>
    </div>

};