import React from 'react';
import {Header} from '../../components/Header/Header';
import {Presentation} from '../../components/Presentation/Presentation';
import {Skills} from '../../components/Skills/Skills';
import {Contacts} from '../../components/Contacts/Contacts';
import {Works} from '../../components/Works/Works';
import {Footer} from '../../components/Footer/Footer';
import styles from './MainPage.module.scss'
import {Resume} from '../../components/Resume/Resume';

export const MainPage = () => {
    return <div className={styles.main_page}>
        <div><Header/></div>
        <div><Presentation/></div>
        <div><Skills/></div>
        <div><Resume/></div>
        <div><Works/></div>
        <div><Contacts/></div>
        <div><Footer/></div>
    </div>
};
