import React from 'react';
import {useCallback} from "react";
import Particles from "react-tsparticles";
import {loadFull} from "tsparticles";
import styles from './Particles.module.scss'


export const Particle = () => {
    const particlesInit = useCallback(async (engine) => {
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        // await console.log(container);
    }, []);

    return (
        <Particles
            className={styles.particles}
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fullScreen: {
                    enable: true,
                    zIndex: -1,
                },
                background: {
                    height: "500px",
                    color: "#252527",
                    position: "50% 50%",
                    // repeat: "no-repeat",
                    size: "cover",
                },
                fpsLimit: 120, interactivity: {
                    events: {
                        onClick: {
                            enable: true, mode: "push",
                        }, onHover: {
                            enable: true, mode: "repulse",
                        }, resize: true,
                    }, modes: {
                        push: {
                            quantity: 2,
                        }, repulse: {
                            distance: 200, duration: 0.9,
                        },
                    },
                }, particles: {
                    color: {
                        value: "#ffffff",
                    }, links: {
                        color: "#ffffff", distance: 150, enable: true, opacity: 0.5, width: 2,
                    }, collisions: {
                        enable: true,
                    }, move: {
                        directions: "none", enable: true, outModes: {
                            default: "bounce",
                        }, random: false, speed: 0.5, straight: false,
                    }, number: {
                        density: {
                            enable: true, area: 800,
                        }, value: 80,
                    }, opacity: {
                        value: 0.2,
                    }, shape: {
                        type: "circle",
                    }, size: {
                        value: {min: 1, max: 2},
                    },
                }, detectRetina: true,
            }}

        />

    );
}