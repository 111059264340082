import React, {FC} from 'react';
import {TitleType} from './types';
import style from './Title.module.scss'


export const Title: FC<TitleType> = ({title}) => {
    return <div className={style.title}>
            <h2>{title}</h2>
        </div>
};
