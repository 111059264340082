import ReactTypingEffect from 'react-typing-effect';
import React from 'react';
import style from './TypingEffect.module.scss'


export const TypingEffect = () => {
    return (
        <>
            {/*<ReactTypingEffect*/}
            {/*    text={["Hello.", "World!"]}*/}
            {/*/>*/}
            <br/>
            <ReactTypingEffect
                className={style.typing_text}
                // speed={1}
                eraseSpeed={50}
                staticText={'A'}
                // eraseDelay={0.1}
                // typingDelay={0.7}
                text={['FRONT-END Developer', 'React Redux', 'JavaScript', 'TypeScript', '']}
                //@ts-ignore
                cursorRenderer={cursor => <div>{cursor}</div>}
                displayTextRenderer={(text, i) => {
                    return (
                        <div>
                            {text.split('').map((char, i) => {
                                const key = `${i}`;
                                return (
                                    <span
                                        key={key}
                                        style={i % 1 === 0 ? {color: '$textColorDecorationOne'} : {}}
                                    >{char}</span>
                                );
                            })}
                        </div>
                    );
                }}
            />
        </>
    );
};