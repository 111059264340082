import React, {useEffect, useState} from 'react';
import style from './Menu.module.scss'
import {Link} from 'react-scroll';
import iconsArrow from './arrow_icon_white.svg'


export const Menu = () => {


    const [btnActive, setBtnActive] = useState('')


    const btnActiveHandler = (value: string) => {
        setBtnActive(value)
        setTimeout(() => {
            setBtnActive('')
        }, (1000))
    }

    const [scroll, setScroll] = useState(0);


    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const handleUpButton = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return <div className={style.menu}>

        <Link
            className={scroll > 300 ? `${style.scroll_body} ${style.active} ` : `${style.scroll_body}`}
            onClick={() => {
                btnActiveHandler('home')
            }}
            activeClass={style.place_object}
            to={'home'}
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={0}
            duration={250}
            delay={1}
            isDynamic={true}
            ignoreCancelEvents={false}
        >
            <div className={style.menu_anim_arrow}>
                <img src={iconsArrow} alt=""/>
            </div>
        </Link>

        <div className={style.block_menu}>
            <div>
                <Link
                    className={btnActive === 'home' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('home')
                    }}
                    activeClass={style.place_object}
                    to={'home'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={0}
                    duration={500}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={false}
                    // spyThrottle={500}
                >
                    Home
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'skills' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('skills')
                    }}
                    activeClass={style.place_object}
                    to={'skills'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={0}
                    duration={900}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Skills
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'resume' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('resume')
                    }}
                    activeClass={style.place_object}
                    to={'resume'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={0}
                    duration={900}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Resume
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'works' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('works')
                    }}
                    activeClass={style.place_object}
                    to={'works'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={0}
                    duration={900}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Works
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'contacts' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('contacts')
                    }}
                    activeClass={style.place_object}
                    to={'contacts'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={0}
                    duration={900}
                    delay={200}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Contacts
                </Link>
            </div>
        </div>

    </div>
};
