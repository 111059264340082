import React, {FC} from 'react';

import style from './Buttons.module.scss'
import {bool} from 'yup';

type PropsType = {
    text: string
    sendCB?: () => void
    link?: string
    type?: any
    disabled?: boolean
}

export const Buttons: FC<PropsType> = ({text, sendCB,link,disabled}) => {

    return <div>
        <div className={style.button_wrap}>
            <a href={link} target="_blank">
                <button onClick={sendCB} className={disabled ? `${style.button} ${style.disabled}` : `${style.button}`} disabled={disabled}>{text}</button>
            </a>
        </div>
    </div>
}