import React, {useState} from 'react';
import style from './BurgerMenu.module.scss'
import {Link} from 'react-scroll';


export const BurgerMenu = () => {
    const [isLockBody, setIsLockBody] = useState(false)
    const addLockBody = () => {
        if (!isLockBody){
            document.body.classList.add('_lock');
            setIsLockBody(true)
        }
        if (isLockBody){
            document.body.classList.remove('_lock');
            setIsLockBody(false)
        }
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [btnActive, setBtnActive] = useState('')
    const isMenuOpenHandler = () => {
        addLockBody()
        setIsMenuOpen(!isMenuOpen)
    }

    const btnActiveHandler = (value: string) => {
        addLockBody()
        setBtnActive(value)
        setIsMenuOpen(false)
        setTimeout(() => {
            setBtnActive('')
        }, (1000))
    }



    return <div className={style.burger_menu}>
        <div className={isMenuOpen ? `${style.menu_btn} ${style.active}` : `${style.menu_btn}`}
             onClick={isMenuOpenHandler}><span></span></div>
        <div className={isMenuOpen ? `${style.burger_menu_body} ${style.active}` : `${style.burger_menu_body}`}>
            <div>
                <Link
                    className={btnActive === 'home' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('home')
                    }}
                    activeClass={'active'}
                    to={'home'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={1}
                    duration={500}
                    delay={1000}
                    isDynamic={true}
                    ignoreCancelEvents={false}
                    // spyThrottle={500}
                >
                    Home
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'skills' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('skills')
                    }}
                    activeClass={'active'}
                    to={'skills'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={1}
                    duration={900}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Skills
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'resume' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('resume')
                    }}
                    activeClass={'active'}
                    to={'resume'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={1}
                    duration={900}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Resume
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'works' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('works')
                    }}
                    activeClass={'active'}
                    to={'works'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={1}
                    duration={900}
                    delay={10}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Works
                </Link>
            </div>
            <div>
                <Link
                    className={btnActive === 'contacts' ? `${style.btn} ${style.active}` : `${style.btn}`}
                    onClick={() => {
                        btnActiveHandler('contacts')
                    }}
                    activeClass="active"
                    to={'contacts'}
                    spy={true}
                    smooth={true}
                    hashSpy={true}
                    offset={1}
                    duration={900}
                    delay={200}
                    isDynamic={true}
                    ignoreCancelEvents={true}
                    // spyThrottle={500}
                >
                    Contacts
                </Link>
            </div>
        </div>

    </div>
};
