import React, {FC} from 'react';
import style from './Footer.module.scss'
import telegramIcon from '../../assets/image/icons/telegram_icon2.svg'
import linkedinIcon from '../../assets/image/icons/linkedin_icon.svg'
import gitHubIcon from '../../assets/image/icons/gitHub_icon.svg'
import twitterIcon from '../../assets/image/icons/twiter_icon.svg'
import codewarsIcon from '../../assets/image/icons/codewars_icon.svg'


export const Footer = () => {


    return <div className={style.footer}>
        <div className={style.container}>
            <div className={style.footer__body}>
                <h4 className={style.footer__title}><a href="https://svetailo.com">Svetailo</a></h4>
                <div className={style.footer__elements}>
                    <IconElement link={'https://t.me/svetailo'} size={'22px'} icon={telegramIcon}/>
                    <IconElement link={'https://www.linkedin.com/in/svetailo/'} size={'18px'} icon={linkedinIcon}/>
                    <IconElement link={'https://github.com/VictorSvetailo'} icon={gitHubIcon}/>
                    <IconElement link={'https://twitter.com/VictorSvetailo'} icon={twitterIcon}/>
                    <IconElement link={'https://www.codewars.com/users/VictorSvetailo'} icon={codewarsIcon}/>
                </div>
                <p>© 2017 - 2023 All Rights Reserved.</p>

            </div>
        </div>
    </div>
}

type PropsType = {
    icon: string
    size?: any
    link?: string
}

export const IconElement: FC<PropsType> = ({icon,size, link}) => {
    return <div className={style.footer__element}>
        <div className={style.footer__element_icon}>
            <a href={link}>
                <img style={{width: `${size}`}} src={icon} alt="icon"/>
            </a>
        </div>
    </div>
}