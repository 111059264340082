import style from './SkillElement.module.scss';
import React from 'react';
import {Fade} from 'react-awesome-reveal';
import Tilt from 'react-parallax-tilt';
import tsIcon from '../../../assets/image/icons/ts_icon.svg';

type PropsType = {
    title: string
    text: string
    icon: string
}

export const SkillElement: React.FC<PropsType> = ({title, text, icon}) => {
    const transitionSpeedTilt = 2000
    const tiltReverse = true


    return <Fade
        className={style.skill_element_fade}
        cascade direction={'up'} duration={900} damping={1} triggerOnce={false}>
        <div className={style.skill_element}>
            <div className={style.skill_element_block}>
                <Tilt className={style.skills_elements_tilt} reset={true} transitionSpeed={transitionSpeedTilt} tiltReverse={tiltReverse}>
                    <div className={style.skill_element_icon_wrap}>
                        <div className={style.skill_element_icon_wrap_wrap}>
                            <img className={style.skill_element_icon} src={icon} alt="React"/>
                        </div>
                    </div>
                </Tilt>
                <h2 className={style.skill_element_title}>{title}</h2>
                <p>{text}</p>
            </div>
        </div>
    </Fade>

};