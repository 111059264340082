import React from 'react';
import style from './Presentation.module.scss'
import {Particle} from '../../utils/Particle/Particle';
import {TypingEffect} from '../../utils/TypingEffect/TypingEffect';
import {MouseParallaxContainer, MouseParallaxChild} from 'react-parallax-mouse';


export const Presentation = () => {
    return <div>
        <div id={'home'} className={style.presentation}>
            <Particle/>
            <div className={style.container}>
                <MouseParallaxContainer globalFactorX={0.07} globalFactorY={0.1}>
                    <div className={style.presentation_block}>
                        <div className={style.wrap}>
                            <div className={style.presentation__elements}>
                                <p>Hi There</p>
                                <div className={style.presentation_title}>
                                    <div>I am</div>
                                    <div className={style.presentation__title_border}>Victor Svetailo</div>
                                </div>
                                <TypingEffect/>
                            </div>
                            <div className={style.presentation__wrap}>
                                <MouseParallaxChild factorX={0.4} factorY={0.1}>
                                    <div className={style.presentation__border}></div>
                                </MouseParallaxChild>
                                <MouseParallaxChild factorX={0.2} factorY={0.3}>
                                    <div className={style.presentation_image}>
                                        <img src={'https://firebasestorage.googleapis.com/v0/b/svetailo-db.appspot.com/o/portfolio%2Fimg%2Fmy-photo%2Fmy-photo.jpeg?alt=media&token=abbc180d-ee33-46f7-8341-e90ba90eafb8'} alt="My photo"/>
                                    </div>
                                </MouseParallaxChild>

                            </div>
                        </div>
                    </div>
                </MouseParallaxContainer>
            </div>

        </div>
    </div>

}