import React from 'react';
import {Title} from '../../common/components/Title/Title';
import style from './Resume.module.scss'
import {Buttons} from '../../common/components/Buttons/Buttons';


export const Resume = () => {
    return (
        <div id={"resume"} className={style.resume}>
            <div>
                <Title title={'Resume'}/>
            </div>
            <div>
             <Buttons link={"https://github.com/VictorSvetailo/cv/blob/main/VictorSvetailoCV.pdf"} text={'View More'}/>
            </div>

        </div>
    );
}