import React, {FC, useEffect, useState} from 'react';
import {Title} from '../../common/components/Title/Title';
import style from './Contacts.module.scss'
import {Buttons} from '../../common/components/Buttons/Buttons';
import axios from 'axios'
import {useFormik} from 'formik';
import loadingSVG from '../../assets/loading/loadingSVG.svg'


export const Contacts = () => {
    const [isSendMessage, setIsSendMessage] = useState<boolean>(false)
    const [isLoadingSVG, setIsLoadingSVG] = useState<boolean>(false)

    let initialValues1;

    const formik = useFormik({
        validate: (values) => {
            if (!values.name) {
                return {
                    name: 'Name is required'
                }
            }
            if (!values.email) {
                return {
                    email: 'Email is required'
                }
            }
        },
        initialValues: {
            name: '',
            email: '',
            message: '',
        },

        onSubmit: values => {
            let message = `
<b>Message from: svetailo.com</b>\n 
<b>Name: ${values.name}</b>\n 
<b>Email: ${values.email}</b>\n 
<b>Message: ${values.message}</b>
`
            const justTryToStealIt = '6031878403:AAG6FAs5rtNJSQaTqXDNPNzLYAWf6sdfVGk'
            const CHAT_ID = '-1001801424579'
            const URI_API = `https://api.telegram.org/bot${justTryToStealIt}/sendMessage`

            fetch(URI_API, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({
                    chat_id: CHAT_ID, parse_mode: 'html', text: message,
                })
            })

            setIsLoadingSVG(true)

            axios.post('https://mail-nodejs.vercel.app/sendMessage', values)
                .then((res) => {
                    setIsLoadingSVG(false)
                    setIsSendMessage(true)
                    formik.resetForm()
                })
                .then((res) => {
                    setTimeout(() => {
                        setIsSendMessage(false)
                    }, (3000))
                })
        },
    });


    return <div>
        <div id={'contacts'} className={style.contacts}>
            <div className={style.container}>
                <Title title={'Contact'}/>
                <div className={style.form__body}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className={style.form__elements}>
                            <div>
                                <input
                                    id="name"
                                    name="name"
                                    type="name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    placeholder={'Name'}
                                    className={formik.errors.name ? `${style.form__name} ${style.error}` : `${style.form__name} `}
                                />
                                {formik.errors.name ? (<div style={{color: 'red'}}>{formik.errors.name}</div>) : null}
                            </div>
                            <div>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    placeholder={'E-mail'}
                                    className={formik.errors.email ? `${style.form__email} ${style.error}` : `${style.form__email} `}
                                />
                                {formik.errors.email ? (<div style={{color: 'red'}}>{formik.errors.email}</div>) : null}
                            </div>
                            <div>
                                <textarea id="message" name="message" onChange={formik.handleChange}
                                          placeholder={'Your message'} value={formik.values.message}
                                          className={style.form__textarea}></textarea>
                            </div>
                        </div>

                        <Buttons disabled={isLoadingSVG} type="submit" text={'Send Message'}/>
                        {isLoadingSVG && <img src={loadingSVG} className={style.loadingSVG} alt=""/>}
                        {isSendMessage && <p className={style.form__notification}>sent successfully...</p>}
                    </form>
                </div>
            </div>
        </div>
    </div>
}